import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Icons } from '../../assets';
import {
  useMutationPages,
  useMutationUser,
  useQueryPages,
  useQueryUser,
  useQueryWallet
} from '../../hooks';
import { COLORS, FONTS, SPACES } from '../../theme';
import { Button, Input, Loading, ModalConfirm } from '../common/component';
import { DropDownlist } from '../common/component/drop-down-list/drop-downlist';
import { Pagination } from '../common/component/pagination/pagination.component';
import { APP_KEYS } from '../common/constants';
import { DivCommon, IconCommon, TitleCommon } from '../common/styles';
import { Circle } from './components/circle/circle';
import { CreateWallet } from './components/create-wallet';
import { MerchTable } from './components/table';
import { TitlePage } from './components/title-page';
import * as Styled from './merch.styled';
import { FilterCounter, WrapperFilterTokens, WrapperFlex } from './merch.styled';

export const Merch = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [query, setQuery] = useSearchParams({ page: '1' });
  const currentPage = +(query.get('page') ?? '1');

  const { isLoading: isLoadingPages, data: pages } = useQueryPages.all();
  const { isLoading: isLoadingUser, data: user } = useQueryUser.getByIdOrToken();
  const { isLoading: isLoadingWalletAllList, data: walletAllList } = useQueryWallet.all({
    page: currentPage,
    keyword: search
  });
  const allPage = pages?.pages.length ?? 0;
  const { mutate: mutateLogout } = useMutationUser.logout();
  const mutateCreatePage = useMutationPages.post(allPage);

  const arrTokens = walletAllList?.walletAddresses || [];

  const total = arrTokens?.reduce(
    (acc: { [key: string]: number }, curr) => {
      const name = curr.token;

      if (!acc[name] || acc[name]) {
        acc[name] = (acc[name] ?? 0) + +curr.balance;
      }
      acc['All'] += +curr.balance;
      return acc;
    },
    { All: 0 }
  );

  const counterList: { balance: number; name: string }[] = Object.entries(total).map((value) => ({
    name: value[0],
    balance: value[1]
  }));
  // const [filterTokens, setFilterTokens] = useState<any >(counterList.find(value => value.name === 'All'));
  const [filterTokens, setFilterTokens] = useState<any>({});

  useEffect(() => {
    if (counterList.length) {
      setFilterTokens(counterList.find((value) => value.name === 'All'));
    }
  }, [arrTokens]);

  const onAddPage = () => {
    mutateCreatePage.mutate({ title: 'New Page' });
  };

  if (isLoadingWalletAllList || isLoadingUser || isLoadingPages) {
    return <Loading className='full-screen' />;
  }

  return (
    <DivCommon width='81.25rem' jc='flex-start' fd='column' height='100svh'>
      <Styled.HeaderContainer>
        <Styled.HeaderTitle>
          {user?.user.nick_name}
          <IconCommon
            icon={Icons.logout}
            background={COLORS.mainRed}
            height={FONTS.SIZES.l}
            cursor='pointer'
            title='logout'
            onClick={() => mutateLogout({})}
          />
        </Styled.HeaderTitle>

        {user?.user.type === 'admin' ? (
          <Button
            width='7.3125rem'
            height='2.1875rem'
            mt={SPACES.l}
            content='Admin'
            type='submit'
            variant='primary'
            onClick={() => navigate(APP_KEYS.ROUTER_KEYS.ADMIN)}
          />
        ) : null}
      </Styled.HeaderContainer>

      <WrapperFlex>
        <TitlePage pageInfo={walletAllList?.pageInfo ?? { id: 1, title: '' }} />
        <WrapperFilterTokens>
          <TitleCommon fs='2rem'>Total:</TitleCommon>
          <DropDownlist
            list={counterList}
            name='filterTokens'
            wrapperStyle={{ width: '12rem', position: 'relative' }}
            listStyle={{ maxWidth: '18.300rem', top: '3rem' }}
            noFormikValue={{
              value: { name: filterTokens?.name ?? 'All' },
              onSetValue: (name, value) => setFilterTokens(value)
            }}
          />
          <FilterCounter>{filterTokens?.balance}</FilterCounter>
        </WrapperFilterTokens>
      </WrapperFlex>

      <Styled.WalletContainer>
        <CreateWallet page={currentPage} />

        <Circle page={currentPage} keyword={search} duration={30} />

        <Input
          className='search'
          startIcon={{ icon: Icons.search }}
          name='search'
          placeholder='Enter'
          width='18.625rem'
          height='2.9375rem'
          noFormikValue={{
            value: search,
            onSetValue: (name, value) => setSearch(value)
          }}
        />
      </Styled.WalletContainer>
      <DivCommon style={{ flex: '1 1 auto' }}>
        <MerchTable data={walletAllList?.walletAddresses ?? []} />
      </DivCommon>

      <DivCommon width='90%' ai='center' margin='1.5rem auto 0.5rem auto'>
        <Styled.PageContainer>
          <Styled.WrapperPagination>
            <Pagination
              onPageChange={(page) => {
                // @ts-ignore
                setQuery((prev) => ({ ...prev, page }));
              }}
              currentPage={currentPage}
              totalCount={pages?.pages.length ?? 0}
              pageSize={1}
            />
          </Styled.WrapperPagination>

          <Styled.WrapperButton>
            <Button
              width='16.75rem'
              height='2.9375rem'
              content='Add Page'
              startIcon={Icons.plusIcon}
              color={COLORS.white}
              onClick={onAddPage}
              type='submit'
              variant='inverseGreen'
              cursor='pointer'
            />
          </Styled.WrapperButton>
        </Styled.PageContainer>
      </DivCommon>
    </DivCommon>
  );
};
